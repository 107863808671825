

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  background-color: rgb(10, 18, 52);
  color: #fff;
}



  

.App-header {
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.home,
.about,
.samples {
  background: #121213;
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #fff;
}

.about {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
}

.pricing {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000000;
}

.notfound {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #000000;
}

.sign-up {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
}

.verify-email {
  min-height: 1200px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #000000;
}

.p-fieldset {
  border-width: 0;
}

.p-fieldset-legend-text {
  border-width: 0;
  margin-bottom: 40px;
  font-family: Impact,fantasy;
  font-size:xx-large;
  border:0;
}