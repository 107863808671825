.market-analysis-container {
    height:400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.market-container {
    height:400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin-top: 80px;
}

.left-market {
    width: 50%;
    margin-left: 5%;
    /* Add more styles for the left market */
}

.right-market {
    width: 50%;
    padding-left:40px;
    /* Add more styles for the right market */
    
}

.image-container img {
    object-fit: contain;
    max-width: 70%;
    cursor: pointer;
}


.ad-copy-p{
    font-size: larger;
}

.tech-list{
    font-size: large;
}


@media screen and (max-width: 960px) {
    .market-container {
        height:80vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
    }
   
.left-market {
    width: 70vw;
    margin-left: 5%;
    /* Add more styles for the left market */
}

.right-market {
    width: 50%;
    padding-left:10px;
    /* Add more styles for the right market */
    
}
.image-container img {
    object-fit: contain;
    max-width: 90%;
    cursor: pointer;
}

}