.bio-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}


.bio-snippet{
    font-size: large;
    padding:20px;
}


@media screen and (max-width: 960px) {

    

    .bio-container {
        height:100vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        margin:05vh;
        margin-top: 10vh;
        margin-right:05vw;
    }
    .bio-snippet{
        font-size: large;
        padding:0px;
    }
}