.section {
    border-radius: 15px;
    padding: 20px;
    margin: 40px;
    padding-left: 80px;
    padding-right: 80px;
}

.section-top{
    padding-top: 40px;
}


.section-filler{
    padding:40px;
    border-bottom: 2px solid rgb(170, 185, 199);
    border-bottom-width: thin;
    display:flex;
    margin-left: 10%;
    margin-right:10%;
    margin-top:5%;
}


  
@media screen and (max-width: 480px) {

    p {
        object-fit: contain;
    }

    .p-fieldset-legend {
        display: none;
    }
   
    .section {
        border-radius: 15px;
        padding: 05vw;
        margin: 40px;
        padding-left: 10vw;
        padding-right: 10vw;
        object-fit: contain;
    }

    .section-filler{
        padding:05vw;
        border-bottom: 2px solid rgb(10, 18, 52);
        border-bottom-width: thin;
        display:flex;
        margin-left: 10%;
        margin-right:10%;
        margin-top:05vh;
    }

    .section-top{
        padding-top: 5vh;
    }

    #section-top-section3 {
       
            padding-top: 22vh;
        
    
    } 
}