.hero-container {
    height:400px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}
/* linear-gradient(90deg, rgb(44, 44, 47) 0%, rgb(0, 0, 0) 100%) */

.left-banner {
    width: 5%;
    /* Add more styles for the left banner */
}

.right-banner {
    width: 70%;
    /* Add more styles for the right banner */
}

.right-banner-text {
    font-size: larger;
    /* Add more styles for the right banner */
}

@media screen and (max-width: 960px) {
    .hero-container {
        height:80vh;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        object-fit: contain;
        padding:20px;
    }
   
    .left-banner {
        width: 20%;
        /* Add more styles for the left banner */
    }

    .right-banner {
        width: 80vw;
        /* Add more styles for the right banner */
    }
    .right-banner-text {
        width: 100%;
        padding:20px;
        /* Add more styles for the right banner */
    }
}