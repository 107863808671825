.footer-container{
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    border-radius: 2px;
    border-color: aliceblue;
    width: 100%;
    padding-left: 80px;
    padding-right: 80px;
}


.footer-subscription{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 24px;
    padding:24px;
    color: #fff;
}

.footer-pointer > p {
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.footer-pointer-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-heading {
    margin-bottom: 24px;
    font-size: 24px;
}

.footer-subscription-text {
    margin-bottom: 24px;
    font-size: 20px;
}

.footer-input {
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border: 1px solid #fff;
}

.footer-links{
    width:100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
}

.footer-link-wrapper {
    display:flex;

}

.footer-link-items{
    display:flex;
    align-items: flex-start;
    margin:16px;
    text-align:left;
    width:160px;
    box-sizing: border-box;
}

.footer-link-items h2 {
    margin-bottom: 16px;

}

.footer-link-items > h2 {
    color: #fff;
    
}

.footer-link-items a {
    color: #fff;
    text-decoration: none;
    margin-bottom: 8px;
}

.footer-link-items a:hover{
    color: #e9e9e9;
    transition:  0.3s ease-out;
}

.footer-email-form h2 {
    margin-bottom: 32px;
}

.footer-input::placeholder {
    color: #b1b1b1
}

.social-icon-link{
    color: #fff;
    font-size: 24px;
}

.social-media{
    max-width: 1000px;
    width: 100%;
}

.social-media-wrap{
    display:flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}

.social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
}
.social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
  }

  .boilerplate {
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
  }
  
  .boilerplate-link {
    padding: 5px;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(33.33% - 20px);
  }

  @media screen and (max-width: 960px) {
    .footer-subscription {
        display:flex;
        padding:80px;

    }

    .footer-container {
        width:100vw;
        text-align: justify;
    }

    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btnv {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }
  
  @media screen and (max-width: 480px) {
    .footer-subscription {
        display:flex;
        padding:80px;
    }

    .footer-container {
        width:100vw;
        text-align: center;
        margin-top: 20vh;
    }

    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btnv {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-media-wrap {
      flex-direction: column;
    }
  }